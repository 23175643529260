import { useEffect } from 'react';

import { ssrBannerManager } from '@confluence/ssr-utilities';
import { useSessionData } from '@confluence/session-data';
import type { BannerStateContainer } from '@confluence/banners';
import { useIsSiteAdmin } from '@confluence/current-user';
import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';

import { ConfluenceEdition } from '../Upgrade/__types__/waitForEditionChangeQuery';

import { usePTUBannerDismissState } from './usePTUBannerDismissState';

const BANNER_NAME = 'PreviouslyTrialedUserBanner';
const BANNER_HEIGHT = 48;

export const useRegisterPreviouslyTrialedUserBanner = (bannerState?: BannerStateContainer) => {
	const { edition } = useSessionData();
	const { dismissBanner, isDismissed } = usePTUBannerDismissState();
	const [experiment, fireExposure] = UNSAFE_noExposureExp(
		'confluence_frontend_prev_premium_trialed_users',
	);
	const statsigFeatureFlagValue = experiment.get<string>('cohort', 'not-enrolled');

	const hasOtherBanners = bannerState?.state?.banners?.some(
		(banner) => banner.name !== BANNER_NAME,
	);

	useEffect(() => {
		if (statsigFeatureFlagValue === 'experiment' || statsigFeatureFlagValue === 'control') {
			fireExposure();
		}
	}, [fireExposure, statsigFeatureFlagValue]);

	const isFeatureFlagEnabled = statsigFeatureFlagValue === 'experiment';

	const { isSiteAdmin } = useIsSiteAdmin();

	const ptuBannerShouldRender =
		isSiteAdmin &&
		edition === ConfluenceEdition.STANDARD &&
		isFeatureFlagEnabled &&
		!hasOtherBanners &&
		!isDismissed;

	if (ptuBannerShouldRender) {
		bannerState?.show(BANNER_NAME, BANNER_HEIGHT);
		if (process.env.REACT_SSR) {
			ssrBannerManager.registerBanner({
				name: BANNER_NAME,
				height: BANNER_HEIGHT,
			});
		}
	} else {
		bannerState?.hide(BANNER_NAME);
	}

	return {
		ptuBannerShouldRender,
		dismissBanner,
	};
};
