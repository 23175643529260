import { useQuery } from '@apollo/react-hooks';

import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution, isUnauthorizedError } from '@confluence/error-boundary';
import { markErrorAsHandled } from '@confluence/graphql';

import type { IsSiteAdminFragment } from './__types__/IsSiteAdminFragment';
import { getIsSiteAdminFromQuery } from './getIsSiteAdminFromQuery';
import { UseIsSiteAdminQuery } from './UseIsSiteAdminQuery.graphql';

export const useIsSiteAdmin = () => {
	const {
		data,
		error,
		loading: isAdminCheckLoading,
	} = useQuery<IsSiteAdminFragment>(UseIsSiteAdminQuery);

	if (error) {
		if (!isUnauthorizedError(error)) {
			getMonitoringClient().submitError(error, {
				attribution: Attribution.ADMIN_EXPERIENCE,
			});
		}
		markErrorAsHandled(error);
	}

	const isSiteAdmin = getIsSiteAdminFromQuery(data);

	return { isSiteAdmin, isAdminCheckLoading };
};
