import { useCallback, useState } from 'react';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
} from '@confluence/storage-manager';

const { PERSISTED_PREVIOUSLY_TRIALED_PREMIUM_USERS_DISMISSED: ptuBannerKey } =
	PERSISTED_KEYS_ON_SERVER;

export const usePTUBannerDismissState = () => {
	const [isDismissed, setIsDismissed] = useState<boolean | undefined>(
		localStorage?.getItemAsBoolean(ptuBannerKey),
	);

	const dismissBanner = useCallback(() => {
		localStorage?.setItem(ptuBannerKey, true);
		setIsDismissed(true);
	}, []);

	return { dismissBanner, isDismissed };
};
