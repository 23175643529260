import gql from 'graphql-tag';

/* Note that GraphQL fragments like this must be in a file that does NOT have the extension ".graphql.js"
  because our build auditor will attempt to verify anything in a "graphql.js" as a full query */
export const IsProductAdminFragment = gql`
	fragment IsProductAdminFragment on Query {
		user(current: true) {
			id
			confluence {
				operations {
					operation
					targetType
				}
			}
		}
	}
`;

export const IsSiteAdminFragment = gql`
	fragment IsSiteAdminFragment on Query {
		isSiteAdmin
	}
`;

export const HasUserAccessAdminRoleFragment = gql`
	fragment HasUserAccessAdminRoleFragment on Query {
		hasUserAccessAdminRole
	}
`;

export const UserPrivilegeFragment = gql`
	fragment UserPrivilegeFragment on Query {
		...IsProductAdminFragment
		...IsSiteAdminFragment
		...HasUserAccessAdminRoleFragment
	}

	${IsProductAdminFragment}
	${IsSiteAdminFragment}
	${HasUserAccessAdminRoleFragment}
`;
