import gql from 'graphql-tag';

import { IsSiteAdminFragment } from './UserPrivilege.fragment';

export const UseIsSiteAdminQuery = gql`
	query UseIsSiteAdminQuery {
		...IsSiteAdminFragment
	}

	${IsSiteAdminFragment}
`;
